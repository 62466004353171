export default {
    data() {
      return {
        project: useState('project'),
        client: useSanctumClient(),
        user: useSanctumUser(),
        route: useRoute(),
        config: useRuntimeConfig(),
        appConfig: useAppConfig(),
      }
    },
    computed: {
      isProjectAdmin() {
        if(this.user && this.user.projects.length){
            const project = this.user.projects.find(e => e.id == this.project.id);
            if(project){
                return project.pivot.is_admin || this.user.is_admin;
            }
        }
        return this.user.is_admin;
      }
    }
  }
