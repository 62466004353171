import revive_payload_client_4sVQNw7RlN from "/home/raci/apps/default/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/raci/apps/default/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/raci/apps/default/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/raci/apps/default/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/raci/apps/default/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/raci/apps/default/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/raci/apps/default/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/raci/apps/default/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/raci/apps/default/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Jozdw60ZsE from "/home/raci/apps/default/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_vfCt6Q18Tc from "/home/raci/apps/default/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/home/raci/apps/default/.nuxt/primevue-plugin.mjs";
import plugin_client_533RIki58s from "/home/raci/apps/default/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_Jozdw60ZsE,
  plugin_vfCt6Q18Tc,
  primevue_plugin_egKpok8Auk,
  plugin_client_533RIki58s
]