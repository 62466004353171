<template>
    <header class="header bg-sky-900" v-if="!route.query.print">
        <nav
            class="inner"
            role="navigation"
            >
            <NuxtLink :to="user ? (user.is_admin || user.projects.length > 1 ? '/projects' : '/') : '/user-not-found'">
                <div class="logo">
                    <img src="/assets/images/raci-logo.png" />
                </div>
            </NuxtLink>

            <div class="menu-setup">
                <NuxtLink to="/" class="text-lg mr-5" :title="project.name + ' tasks'" v-if="project">
                    <div class="rounded border-white border px-3 py-1" v-if="project">{{ project.name }}</div>
                </NuxtLink>
                <NuxtLink to="/setup" :title="project.name + ' settings'" v-if="project && isProjectAdmin">
                    <span class="pi pi-cog" />
                </NuxtLink>
                <NuxtLink to="/projects" title="Projects list" class="ml-5" v-if="user && (user.is_admin || user.projects.length > 1)">
                    <span class="pi pi-folder" />
                </NuxtLink>

                <div class="inline ml-4 p-1.5 border rounded-full w-4 h-4 text-sm cursor-pointer" @click="toggleUser" v-if="user">{{ user.firstname[0] + user.lastname[0] }}</div>
            </div>
        </nav>
        <Popover ref="op_user">
            <div>
                <div class="text-lg px-4 pt-2">{{ user.name }}</div>
                <div class="text-xs border-b-primary-300 border-b px-4 pb-2">{{ user.email }}</div>
                <ul class="p-4">
                    <li>
                        <NuxtLink :to="config.public.api_endpoint + '/auth/logout'">
                            <span class="pi pi-sign-out mr-2" /> Logout
                        </NuxtLink>
                    </li>
                </ul>
            </div>
        </Popover>
    </header>
</template>

<script>
import mainMixin from '~/mixins/main.mixin.js';

export default{
    mixins: [mainMixin],
    methods: {
        async init() {
            useHead({
                title: 'RACI app',
            })
            if(!this.route.query.print){
                if(!this.user){
                    if(this.route.name != 'user-not-found'){
                        this.client('/sanctum/csrf-cookie').then(response => {
                            this.client('/api/user').then(response => {
                                this.user = response;
                                this.init();
                            })
                            .catch((e) => {
                                window.location.href = this.config.public.api_endpoint + '/auth/redirect';
                            });
                        })
                        .catch((e) => {
                            window.location.href = this.config.public.api_endpoint + '/auth/redirect';
                        });

                    }
                }else if(!this.project){
                    if(this.user.is_admin || this.user.projects.length > 1){
                        this.$router.push('/projects');
                    }else{
                        if(this.user.projects.length == 1){
                            this.project = await this.client('/api/projects/' + this.user.projects[0].id);
                            this.$router.push('/');
                        }
                    }

                }else{
                    useHead({
                        title: 'RACI app - ' + this.project.name,
                    })
                }
            }
        },
        toggleUser(event) {
            this.$refs.op_user.toggle(event);
        },
        checkAuthentication(){
            this.client('/api/user')
                .then(_ => {})
                .catch(_ => { window.location.href = this.config.public.api_endpoint + '/auth/redirect'});
        }
    },
    mounted(){
        this.init();
        setInterval(() => this.checkAuthentication(), 5 * 1000 * 60);
    }
}
</script>

