import { default as indexhc1YLNUVGxMeta } from "/home/raci/apps/default/pages/index.vue?macro=true";
import { default as projectstnFtA3jDtVMeta } from "/home/raci/apps/default/pages/projects.vue?macro=true";
import { default as _91project_id_93_45_91category_id_93_45_91role_id_93URwxFtPyBlMeta } from "/home/raci/apps/default/pages/roles/[project_id]-[category_id]-[role_id].vue?macro=true";
import { default as setuphDJSHPg3zbMeta } from "/home/raci/apps/default/pages/setup.vue?macro=true";
import { default as user_45not_45foundrdjLiU14SKMeta } from "/home/raci/apps/default/pages/user-not-found.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/raci/apps/default/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/home/raci/apps/default/pages/projects.vue").then(m => m.default || m)
  },
  {
    name: "roles-project_id-category_id-role_id",
    path: "/roles/:project_id()-:category_id()-:role_id()",
    component: () => import("/home/raci/apps/default/pages/roles/[project_id]-[category_id]-[role_id].vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    component: () => import("/home/raci/apps/default/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: "user-not-found",
    path: "/user-not-found",
    component: () => import("/home/raci/apps/default/pages/user-not-found.vue").then(m => m.default || m)
  }
]